import React from 'react';
import { graphql } from 'gatsby';
import { DefaultLayout } from '../layouts/DefaultLayout';
import styled from '@emotion/styled';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { colors, device } from '../components/layout/GlobalStyles';
import { Seo } from '@/Seo';

const StyledTemplate = styled.div`
    margin-bottom: 6em;
    padding: 0em 1em;
    .blue-supplement-panel {
        margin: 1em 0em;
        background: ${colors.blueGradient};
        border-radius: 16px;
        text-align: center;
        padding: 1em 0em;
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
            color: ${colors.white};
            font-weight: 700;
        }
    }
    a {
        text-decoration: none;
        color: ${colors.blueLight};
        font-weight: 600;
        &:hover {
            text-decoration: underline;
        }
    }
    .scroll-container {
        overflow-x: scroll;
        .table-container {
            width: 200vw;
        }
    }
    .health-sharing-table {
        margin: 2em 0em;
        min-height: 450px;
        min-width: 450px;
    }
    h1 {
        font-size: 42px;
        font-weight: 900;
        margin: 1em 0em;
        background: radial-gradient(142.84% 80.36% at 0% 100%, #00526f 0%, #218fb6 100%)
            /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
    }
    h2,
    h3 {
        margin: 1em 0em;
        background: radial-gradient(142.84% 80.36% at 0% 100%, #00526f 0%, #218fb6 100%)
            /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
    }
    .blue-box {
        background: ${colors.blueGradient};
        color: ${colors.white};
        border-radius: 32px;
        margin: 0 auto;
        padding: 1em;
        > p {
            color: ${colors.white};
        }
        > h5 {
            font-size: 24px;
            color: ${colors.white};
        }
    }
    p {
        margin: 1em 0em;
    }
    h3 {
        font-size: 24px;
    }
    .mdx-image {
        border-radius: 16px;
        margin: 2em 0em 1em;
    }
    .blue-box-membership {
        background: ${colors.blueGradient};
        color: ${colors.white};
        text-align: center;
        border-radius: 64px;
        button {
            margin: 2em auto 0em;
        }
        p {
            color: ${colors.white};
            margin: 1em 0em;
            font-weight: 600;
            font-size: 36px;
        }
    }

    @media ${device.tablet} {
        .blue-supplement-panel {
            margin: 4em 0em;
            padding: 2em 0em;
            p {
                font-size: 32px;
                line-height: 42px;
            }
        }
        .blue-box-membership {
            background: ${colors.blueGradient};
            color: ${colors.white};
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            border-radius: 64px;
            min-height: 321px;
            p {
                font-size: 59px;
                margin: 0.5em;
            }
        }
        h5 {
            font-size: 12px;
            margin: 1em 0em;
        }
        max-width: 680px;
        margin: 0 auto;
        margin-bottom: 4em;
        .scroll-container {
            overflow-x: scroll;
            .table-container {
                width: 170vw;
            }
        }
    }
    @media ${device.laptop} {
        max-width: 1180px;
        margin: 0 auto;
        .scroll-container {
            overflow-x: hidden;
        }
        .health-sharing-table {
            height: 100%;
            margin: 4em auto;
            width: 1120px;
        }
    }
    @media ${device.laptopL} {
        .blue-supplement-panel {
            margin: 4em 0em;
            padding: 2em 0em 4em;
            p {
                margin-bottom: 0.5em;
                font-size: 59px;
                line-height: 67px;
            }
        }
        .scroll-container {
            overflow-x: hidden;
        }
        .table-container {
            max-width: 100vw;
            margin-left: 3em;
        }
        .health-sharing-table {
            height: 100%;
            margin: 4em auto;
            width: 1120px;
        }
        section {
            display: flex;
            div {
                padding: 1.2em 0em;
                max-width: 640px;
            }
            > div:nth-child(1) {
                padding-right: 2em;
                margin-right: 1em;
            }
        }
        max-width: 1280px;
        margin: 0 auto;
        .blue-box {
            max-width: 900px;
            margin: 4em auto 2em;
            height: 300px;
            padding: 3.5em;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
`;

const Blog = ({ data }) => {
    const { mdx } = data;
    const { meta } = mdx.frontmatter;
    return (
        <DefaultLayout
            heroImage={mdx.frontmatter.images[0]}
            heroText={mdx.frontmatter.heroText}
            title={mdx.frontmatter.title}
            type={mdx.frontmatter.type}
        >
            <Seo {...meta} />
            <StyledTemplate>
                <MDXRenderer images={mdx.frontmatter.images}>{mdx.body}</MDXRenderer>
            </StyledTemplate>
        </DefaultLayout>
    );
};

export const query = graphql`
    query ($id: String!) {
        mdx(id: { eq: $id }) {
            frontmatter {
                meta {
                    title
                    description
                }
                title
                breadCrumbs
                type
                slug
                images {
                    childImageSharp {
                        gatsbyImageData(quality: 100, placeholder: NONE)
                    }
                }
            }
            body
        }
    }
`;

export default Blog;
